import { NEAR_PLANE_DISTANCE } from "@/alignment-tool/alignment-steps/alignment";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { CadModelObject } from "@/object-cache";
import {
  useBoxControlsClippingPlanes,
  useBoxControlsContext,
} from "@/utils/box-controls-context";
import {
  BoxControls,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  FxaaPass,
  GridPlane,
  TransformControls,
  UniformLights,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { reproportionCamera } from "@faro-lotv/lotv";
import { useTheme } from "@mui/material";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box3,
  Group,
  Object3D,
  PerspectiveCamera,
  Plane,
  Vector2,
  Vector3,
} from "three";
import { CadSubscene } from "../overview-mode/cad-subscene";
import { RenderDispatch } from "../overview-mode/render-dispatch";
import { useRotationAroundGravity } from "./align-to-cad-utils";

export const FLOOR_OVERLAP = 1.2;

export type ModelElevationSceneProps = {
  /** The active cad model for alignment */
  cadModelObject: CadModelObject;

  /** The bounding box of the cad model in world */
  cadBox: Box3;

  /** the initial elevation value */
  elevation?: number;

  /** callback function to update elevation */
  updateElevation(elevation: number): void;

  /** the flag to indicate if the clipping box is enabled by user */
  isClippingBoxEnabled: boolean;

  /** the camera that is used to render the model */
  camera: PerspectiveCamera;
};

/** @returns the scene for set the elevation in cad model */
export function ModelElevationScene({
  cadModelObject,
  cadBox,
  elevation,
  updateElevation,
  isClippingBoxEnabled,
  camera,
}: ModelElevationSceneProps): JSX.Element {
  const theme = useTheme();

  const { extents, cadCenter } = useMemo(() => {
    const bboxSize = cadBox.getSize(new Vector3());
    const extents = new Vector2(
      bboxSize.x * FLOOR_OVERLAP,
      bboxSize.z * FLOOR_OVERLAP,
    );
    const cadCenter = cadBox.getCenter(new Vector3());
    return { extents, cadCenter };
  }, [cadBox]);

  const [initPosition] = useState(() => {
    const center = cadCenter.clone();

    if (elevation !== undefined) {
      center.y = elevation;
    }
    return center;
  });

  useObjectVisibility(cadModelObject, true);

  const size = useThree((s) => s.size);

  const [isElevationArrowControlInUse, setElevationArrowControlInUse] =
    useState<boolean>(false);

  // this useEffect fixing initial orientation of CAD model at opening elevation scene.
  useEffect(() => {
    // while user operating with elevation arrow control new parameters of the camera
    // might be computed wrong. Condition below prevent updating camera while elevation
    // control is in use.
    if (!isElevationArrowControlInUse) {
      const diagonal = cadBox.getSize(new Vector3()).length();
      camera.position.set(
        cadCenter.x - diagonal,
        cadCenter.y + 0.1 * diagonal,
        cadCenter.z - 0.5 * diagonal,
      );

      camera.updateMatrixWorld(true);
      const cameraFarFactor = 50;
      camera.far = diagonal * cameraFarFactor;
      camera.near = NEAR_PLANE_DISTANCE;
      reproportionCamera(camera, size.width / size.height);
    }
  }, [cadBox, camera, cadCenter, size, isElevationArrowControlInUse]);
  // plane elevation value obtained from TransformControls widget.
  // It is in the same CS and units as a CAD model (W.C.S, meters)
  // note that this value is updated on any movement of the plane in the widget
  const [planeElevation, setPlaneElevation] = useState<number>(0);

  const [clippingBoxInUse, setClippingBoxInUse] = useState(false);

  const planeGroup = useRef<Group | null>(null);

  const pickPlaneHeight = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      const { point } = event.intersections[0];

      // event.delta is a distance between pointerDown and pointerUp in pixels.
      // if it's 2 or less pixels it was a pick of new elevation plane
      // if it's more, then it's considered as OrbitControl movement and plane elevation will be not updated
      if (planeGroup.current && !clippingBoxInUse && event.delta <= 2) {
        planeGroup.current.position.setY(point.y);
        setPlaneElevation(point.y);

        updateElevation(point.y);
      }
    },
    [clippingBoxInUse, updateElevation],
  );

  const onElevationArrowControlPointerDown = useCallback((e?: Event) => {
    setElevationArrowControlInUse(true);
    e?.stopPropagation();
  }, []);

  // update the plane elevation value if sheet elevation is changed
  useEffect(() => {
    if (elevation !== undefined) {
      setPlaneElevation(elevation);
      planeGroup.current?.position.setY(elevation);
    }
  }, [elevation]);

  // update the plane elevation value after first rendering
  useNonExhaustiveEffect(() => {
    if (planeGroup.current) {
      setPlaneElevation(planeGroup.current.position.y);
      updateElevation(planeGroup.current.position.y);
    }
  }, []);

  const onElevationArrowControlPointerUp = useCallback(
    (e?: Event) => {
      if (!clippingBoxInUse) {
        // update value in Redux store at ButtonUp event (while current value updates managed by useState)
        updateElevation(planeElevation);
      }
      setElevationArrowControlInUse(false);
      e?.stopPropagation();
    },
    [clippingBoxInUse, planeElevation, updateElevation],
  );

  // TransformControls can be rendered only at second pass of rendering,
  // because it depends from ref to Elevation plane
  // useState and useEffect below managing rendering of TransformControls at second re-rendering
  const [isFirstRenderingDone, SetFirstRenderingDone] = useState(false);
  useEffect(() => {
    if (planeGroup.current) SetFirstRenderingDone(true);
    return () => {
      SetFirstRenderingDone(false);
    };
  }, []);

  /** Position of the clipping box */
  const [boxPosition, setBoxPosition] = useState(() =>
    cadBox.getCenter(new Vector3()),
  );

  /** Scale of the clipping box. Slightly inflate the box to avoid Z-fighting of the box with CAD model */
  const boxInflateFactor = 1.001;
  const [boxSize, setBoxSize] = useState(() =>
    cadBox.max.clone().sub(cadBox.min).multiplyScalar(boxInflateFactor),
  );

  const { clippingPlanes, setClippingPlanes } = useBoxControlsContext();

  useEffect(() => {
    const initialPlanes = cadModelObject.clippingPlanes;
    const initialClipping = cadModelObject.clipping;
    cadModelObject.clippingPlanes = clippingPlanes ?? [];
    cadModelObject.clipping = true;

    return () => {
      cadModelObject.clippingPlanes = initialPlanes;
      cadModelObject.clipping = initialClipping;
    };
  }, [cadModelObject, clippingPlanes]);

  // This contains the clipping planes ordered to highlith which ones intersect in the
  // minimum and in the maximum
  const clippingBoxPlanes = useBoxControlsClippingPlanes();

  // detect when user disable clipping box and store new position and size for next activation of the clipping box.
  useEffect(() => {
    if (!isClippingBoxEnabled && clippingBoxPlanes) {
      const { min, max } = clippingBoxPlanes;
      setBoxPosition(
        new Vector3(
          0.5 * (max[0].constant - min[0].constant),
          0.5 * (max[1].constant - min[1].constant),
          0.5 * (max[2].constant - min[2].constant),
        ),
      );

      setBoxSize(
        new Vector3(
          max[0].constant + min[0].constant,
          max[1].constant + min[1].constant,
          max[2].constant + min[2].constant,
        ),
      );
    }
  }, [clippingBoxPlanes, isClippingBoxEnabled]);

  const computeClippingPlanesWorldPosition = useCallback(
    (planes: Plane[]) => {
      setClippingPlanes(planes);
    },
    [setClippingPlanes],
  );

  const quaternion = useRotationAroundGravity(cadModelObject.iElement.id);

  return (
    <>
      <UniformLights />
      {isFirstRenderingDone && (
        <CadRenderer
          cadModel={cadModelObject}
          onClick={pickPlaneHeight}
          clippingPlanes={clippingPlanes}
        />
      )}

      {isClippingBoxEnabled && (
        <BoxControls
          position={boxPosition}
          size={boxSize}
          clipInside={false}
          enableTranslateX={false}
          enableTranslateY={false}
          enableTranslateZ={false}
          enableRotateX={false}
          enableRotateZ={false}
          enableRotateY={false}
          onInteractionStarted={() => setClippingBoxInUse(true)}
          onInteractionStopped={() => setClippingBoxInUse(false)}
          clippingPlanesChanged={computeClippingPlanesWorldPosition}
        />
      )}

      <group position={initPosition} quaternion={quaternion} ref={planeGroup}>
        {!isClippingBoxEnabled && (
          <GridPlane
            size={extents}
            backgroundColor={theme.palette.gridBackground}
            backgroundOpacity={0.8}
            lineColor={theme.palette.gridLines}
            lineOpacity={0.5}
            borderColor={theme.palette.gridLines}
            borderWidth={0.1}
          />
        )}
      </group>
      <ExplorationControls
        enabled={!clippingBoxInUse && !isElevationArrowControlInUse}
        target={cadCenter}
        disableInertia
        hidePivot
      />
      {isFirstRenderingDone && !isClippingBoxEnabled && planeGroup.current && (
        <group>
          <TransformControls
            object={planeGroup.current}
            camera={camera}
            showX={false}
            showZ={false}
            onMouseDown={onElevationArrowControlPointerDown}
            onMouseUp={onElevationArrowControlPointerUp}
            onTransformChanged={(position: Vector3) =>
              setPlaneElevation(position.y)
            }
          />
        </group>
      )}
      <EffectPipelineWithSubScenes>
        <CadSubscene cadModel={cadModelObject} enabled />
        <FilteredRenderPass
          filter={(obj: Object3D) =>
            obj.userData.type !== RenderDispatch.CadModel
          }
          clear={false}
          clearDepth={false}
        />
        <FxaaPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
