import { useStoreActiveCameras } from "@/components/common/view-runtime-context";
import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import {
  showEverything,
  SnapshotRenderer,
} from "@/components/r3f/renderers/snapshot-renderer";
import { useCameraParametersIfAvailable } from "@/components/r3f/utils/camera-parameters";
import { CameraSync } from "@/components/r3f/utils/camera-sync";
import { MiniMap, MiniMapActions } from "@/components/r3f/utils/minimap";
import { useOverlayElements } from "@/modes/overlay-elements-context";
import {
  selectBestModelCameraFor360,
  selectBestWayPointForElement,
} from "@/modes/walk-mode/animations/pano-to-model";
import { WalkScene } from "@/modes/walk-mode/walk-scene";
import { ViewType } from "@/modes/walk-mode/walk-types";
import {
  selectShouldSyncCamerasOnWaypoint,
  selectShouldSyncCamerasRotation,
  setCompareElementId,
  setSyncCamerasOnWaypoint,
  setSyncCamerasRotation,
} from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { getCameraAnimationTime } from "@/utils/camera-animation-time";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import {
  selectHasValidPose,
  useNonExhaustiveEffect,
  View,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementImg360,
  isIElementGenericPointCloudStream,
  isIElementImg360,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { RAD_TO_DEG, reproportionCamera } from "@faro-lotv/lotv";
import { useThree, Vector3 as Vector3Prop } from "@react-three/fiber";
import { Suspense, useCallback, useMemo, useRef, useState } from "react";
import { Camera, Vector3 } from "three";
import { useSyncCameras } from "../walk-mode/hooks/use-sync-cameras";
import { SplitInitialState, SplitState } from "./split-state";

/**
 * @returns a two view scene to coordinate left and white views for a split scene
 */
export function SplitScene({
  mainScene,
  mainWalkElement,
  compareScene,
  compareWalkElement,
  initialState,
  mainSceneFilter,
  compareSceneFilter,
}: SplitState & { initialState: SplitInitialState | undefined }): JSX.Element {
  const store = useAppStore();

  const background = useThree((s) => s.scene.background);
  const leftCamera = useThree((s) => s.camera);
  const rightCamera = useMemo(() => leftCamera.clone(), [leftCamera]);
  const [activeCamera, setActiveCamera] = useState(leftCamera);
  const shouldSyncCamerasRotation = useAppSelector(
    selectShouldSyncCamerasRotation,
  );
  const shouldSyncCamerasOnWaypoint = useAppSelector(
    selectShouldSyncCamerasOnWaypoint,
  );
  const dispatch = useAppDispatch();

  const [miniMapCameraPosition, setMiniMapCameraPosition] =
    useState<Vector3Prop>();

  const syncCameras = useSyncCameras(
    leftCamera,
    rightCamera,
    mainScene,
    compareScene,
    mainSceneFilter,
    compareSceneFilter,
    mainWalkElement,
    compareWalkElement,
    shouldSyncCamerasOnWaypoint,
    shouldSyncCamerasRotation,
  );

  /** This function animates the camera to a new position. The camera locator on the minimap is animated as well. */
  const moveBothCamerasFromMiniMap = useCallback(
    (el: IElementImg360) => {
      // when user clicked on placeholder in minimap we always force "Follow" mode
      // to avoid misinterpretation of UI controls by user and over-complication of the logic
      // to reflect all combination of lock/follow/orientationAccurate
      dispatch(setSyncCamerasOnWaypoint(true));

      const camerasAngleInDegrees =
        leftCamera.quaternion.angleTo(rightCamera.quaternion) * RAD_TO_DEG;

      // do not unlock cameras rotation if they are already co-directional (within 1 deg of angular difference)
      if (
        camerasAngleInDegrees > 1 &&
        (mainSceneFilter === SceneFilter.Pano ||
          compareSceneFilter === SceneFilter.Pano)
      ) {
        // if cameras orientation is not the same we unlock rotation then if new target position is accurate,
        //  they will be locked and synced in position and orientation automatically
        dispatch(setSyncCamerasRotation(false));
      }

      syncCameras.syncSplitSceneCameras({
        target: selectBestWayPointForElement(
          el,
          leftCamera,
          mainScene.activeSheetFor2DNavigation,
          shouldSyncCamerasOnWaypoint,
        )(store.getState()),
        isMainScreen: true,
        dispatch,
        shouldSyncCamerasOnWaypoint: true,
        shouldSyncCamerasRotation,
      });

      // After the user select something on the minimap shrink it if it's full screen
      miniMapActions.current?.shrink();
    },
    [
      dispatch,
      leftCamera,
      rightCamera.quaternion,
      mainSceneFilter,
      compareSceneFilter,
      syncCameras,
      mainScene.activeSheetFor2DNavigation,
      shouldSyncCamerasOnWaypoint,
      store,
      shouldSyncCamerasRotation,
    ],
  );

  /**
   * Correctly set the initial position of the right/compare camera from
   * when the component is mounted the first time during switch from single screen walk mode
   * to split screen.
   */
  useNonExhaustiveEffect(() => {
    if (initialState && initialState.rightCam) {
      return;
    }
    if (isIElementImg360(compareWalkElement)) {
      const transform = selectPanoCameraTransform(
        compareWalkElement,
        compareScene.activeSheetFor2DNavigation,
      )(store.getState());
      rightCamera.position.fromArray(transform.position);
    }
    if (
      isIElementImg360(mainWalkElement) &&
      isIElementGenericPointCloudStream(compareWalkElement)
    ) {
      rightCamera.position.copy(
        selectBestModelCameraFor360(
          mainWalkElement,
          mainScene.activeSheetFor2DNavigation,
        )(store.getState()),
      );
    }
  }, []);

  useCameraParametersIfAvailable(leftCamera, initialState?.camera);
  useCameraParametersIfAvailable(rightCamera, initialState?.rightCam);

  const cameras = useMemo(
    () => [leftCamera, rightCamera],
    [leftCamera, rightCamera],
  );

  useStoreActiveCameras(cameras);

  const { firstScreen, secondScreen, miniMap, minimapCanvas } =
    useOverlayElements();
  const miniMapActions = useRef<MiniMapActions>(null);

  const shouldShowMainPlaceholders = useAppSelector(
    (state) =>
      !isIElementImg360(mainWalkElement) ||
      (selectHasValidPose(mainWalkElement)(state) &&
        !!mainWalkElement.isRotationAccurate),
  );

  const shouldShowComparePlaceholders = useAppSelector(
    (state) =>
      !isIElementImg360(compareWalkElement) ||
      (selectHasValidPose(compareWalkElement)(state) &&
        !!compareWalkElement.isRotationAccurate),
  );

  // The left camera is the default r3f camera used also for other modes.
  // On unmount, the 'View' component writes an invalid aspect ratio prop
  // to the camera. Therefore, on unmount we need to reproportion the left
  // camera according to the r3f canvas' size.
  useReproportionCameraOnUnmount(leftCamera);

  return (
    <>
      <View
        camera={leftCamera}
        trackingElement={firstScreen}
        background={background}
        hasSeparateScene
      >
        {/** Since it has a separate scene, the snapshot filter shows everything */}
        <Suspense fallback={<SnapshotRenderer filter={showEverything} />}>
          <CameraSync
            source={activeCamera}
            shouldSync={shouldSyncCamerasRotation}
            shouldSyncFov
          />
          <WalkScene
            sheetForElevation={mainScene.activeSheetFor2DNavigation}
            activeElement={mainWalkElement}
            targetQuaternion={syncCameras.leftCameraQuaternion}
            placeholders={mainScene.panos}
            shouldShowPlaceholders={shouldShowMainPlaceholders}
            paths={mainScene.paths}
            annotations={mainScene.annotations}
            onUserInteracted={() => {
              setActiveCamera(leftCamera);
            }}
            onUserWalkedTo={(p) => {
              syncCameras.setLeftCameraPosition(p);
              setMiniMapCameraPosition(p);
            }}
            onActiveElementChanged={(element) => {
              if (!isIElementModel3dStream(element)) {
                // CAD managed by its own slice using setActiveCad
                dispatch(setActiveElement(element.id));
              }
            }}
            onCameraMovedViaAnimation={(p) => {
              syncCameras.onCameraMoved();
              setMiniMapCameraPosition(p);
            }}
            onCameraMovedViaControls={(pos) =>
              miniMapActions.current?.centerCameraOn(pos)
            }
            onWayPointChanged={(t) => {
              syncCameras.moveSyncCamerasToNewTarget(t, true);
            }}
            onCameraStartedTranslating={syncCameras.onCameraMoved}
            viewType={ViewType.LeftView}
            onAnimationFinished={() => {
              if (mainSceneFilter === SceneFilter.Pano) {
                syncCameras.setWaitForAutoLockLeft(false);
              }
            }}
          />
        </Suspense>
        {syncCameras.leftCameraPosition &&
          mainSceneFilter !== SceneFilter.Pano && (
            <CameraAnimation
              position={syncCameras.leftCameraPosition}
              quaternion={syncCameras.leftCameraQuaternion}
              duration={getCameraAnimationTime(
                leftCamera,
                syncCameras.leftCameraPosition,
              )}
              onAnimationFinished={() => {
                syncCameras.setLeftCameraPosition(undefined);
                syncCameras.setWaitForAutoLockLeft(false);
              }}
            />
          )}
      </View>

      <View
        camera={rightCamera}
        trackingElement={secondScreen}
        background={background}
        hasSeparateScene
      >
        {/** Since it has a separate scene, the snapshot filter shows everything */}
        <Suspense fallback={<SnapshotRenderer filter={showEverything} />}>
          <CameraSync
            source={activeCamera}
            shouldSync={shouldSyncCamerasRotation}
            shouldSyncFov
          />
          <WalkScene
            sheetForElevation={compareScene.activeSheetFor2DNavigation}
            activeElement={compareWalkElement}
            targetQuaternion={syncCameras.rightCameraQuaternion}
            placeholders={compareScene.panos}
            shouldShowPlaceholders={shouldShowComparePlaceholders}
            paths={compareScene.paths}
            annotations={compareScene.annotations}
            // ignore active element change in secondary view on purpose, so when the splitscreen closes the main view item is active
            onUserWalkedTo={(p) => {
              syncCameras.setRightCameraPosition(p);
              setMiniMapCameraPosition(p);
            }}
            onUserInteracted={() => {
              setActiveCamera(rightCamera);
            }}
            onCameraMovedViaAnimation={(p) => {
              syncCameras.onCameraMoved();
              setMiniMapCameraPosition(p);
            }}
            onCameraMovedViaControls={(pos) =>
              miniMapActions.current?.centerCameraOn(pos)
            }
            onCameraStartedTranslating={syncCameras.onCameraMoved}
            viewType={ViewType.RightView}
            onActiveElementChanged={(element) => {
              if (isIElementImg360(element)) {
                dispatch(setCompareElementId(element.id));
              }
            }}
            onWayPointChanged={(t) => {
              syncCameras.moveSyncCamerasToNewTarget(t, false);
            }}
            onAnimationFinished={() => {
              if (compareSceneFilter === SceneFilter.Pano) {
                syncCameras.setWaitForAutoLockRight(false);
              }
            }}
          />
        </Suspense>
        {syncCameras.rightCameraPosition &&
          compareSceneFilter !== SceneFilter.Pano && (
            <CameraAnimation
              position={syncCameras.rightCameraPosition}
              quaternion={syncCameras.rightCameraQuaternion}
              duration={getCameraAnimationTime(
                rightCamera,
                syncCameras.rightCameraPosition,
              )}
              onAnimationFinished={() => {
                syncCameras.setRightCameraPosition(undefined);
                syncCameras.setWaitForAutoLockRight(false);
              }}
            />
          )}
      </View>

      {miniMap && secondScreen && (
        <MiniMap
          actions={miniMapActions}
          canvasElement={minimapCanvas}
          trackingElement={miniMap}
          sheetElements={mainScene.activeSheets}
          activeSheetFor2DNavigation={
            mainScene.activeSheetFor2DNavigation ?? mainScene.activeSheets[0]
          }
          camera={leftCamera}
          secondScreenCamera={rightCamera}
          cameraPosition={miniMapCameraPosition ?? leftCamera.position}
          showUserMarker
          shouldShowViewCone={
            !isIElementImg360(mainWalkElement) ||
            !!mainWalkElement.isRotationAccurate
          }
          shouldShowViewConeSecondCamera={
            !isIElementImg360(compareWalkElement) ||
            !!compareWalkElement.isRotationAccurate
          }
          onPlaceholderClicked={moveBothCamerasFromMiniMap}
          onMinimapClicked={(pos: Vector3) => {
            if (mainSceneFilter !== SceneFilter.Pano) {
              // Keep the camera height as it is
              pos.y = leftCamera.position.y;
              syncCameras.setLeftCameraPosition(pos);
            }

            if (compareSceneFilter !== SceneFilter.Pano) {
              // Keep the camera height as it is
              pos.y = rightCamera.position.y;
              syncCameras.setRightCameraPosition(pos);
            }
          }}
        />
      )}
    </>
  );
}

function useReproportionCameraOnUnmount(camera: Camera): void {
  const size = useThree((s) => s.size);

  useNonExhaustiveEffect(
    () => () => {
      reproportionCamera(camera, size.width / size.height);
    },
    [camera],
  );
}
