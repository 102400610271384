import {
  EventType,
  ExportPointCloudProperties,
} from "@/analytics/analytics-events";
import { HelpPopover } from "@/components/ui/help-popover";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { useBoxControlsClippingPlanes } from "@/utils/box-controls-context";
import {
  canExport,
  computeExportLimit,
  DEFAULT_DENSITY_VALUE,
} from "@/utils/pc-export-utils";
import { planesToArray } from "@/utils/planes-to-array";
import {
  FaroButton,
  FaroChipToggle,
  FaroRadio,
  FaroRadioGroup,
  FaroSlider,
  FaroSliderProps,
  FaroText,
  neutral,
  TextField,
  TranslateVar,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericDataset,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIElementWorldMatrix,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  PointCloudFormat,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { FormControlLabel, Stack } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { Matrix4, Vector3 } from "three";
import { OBB } from "three-stdlib";
import { useCurrentScene } from "../../mode-data-context";
import { returnToPreviousMode } from "./../export-mode-utils";

/**
 * List of possible point cloud densities for the export.
 * The value is the distance between points in mm.
 * The values are ordered from the lowest to the highest density.
 */
const CUSTOM_DENSITY_VALUES = [
  {
    value: 2,
  },
  {
    value: 4,
  },
  {
    value: 8,
  },
  {
    value: 16,
  },
  {
    value: 32,
  },
  {
    value: 64,
  },
] satisfies FaroSliderProps["marks"];

type PointCloudExportFormProps = {
  /** The formats supported by the point cloud API */
  formats?: PointCloudFormat[];

  /** Whether an export is currently in progress. */
  isExportInProgress: boolean;

  /** Callback when the export state changes. */
  onChangeIsExportInProgress(isExportInProgress: boolean): void;
};

/** @returns the form to select what format to use to export a PointCloud SubVolume */
export function PointCloudExportForm({
  formats,
  isExportInProgress,
  onChangeIsExportInProgress,
}: PointCloudExportFormProps): JSX.Element {
  const { pointCloudApiClient, coreApiClient } = useApiClientContext();

  const pointCloud = useCurrentScene().main;
  assert(
    pointCloud && isIElementGenericPointCloudStream(pointCloud),
    "An active pointcloud is required for the Export tool",
  );

  const worldMatrixYUp = useAppSelector(
    selectIElementWorldMatrix(pointCloud.id),
  );

  const [chosenFormat, setChosenFormat] = useState<PointCloudFormat>();

  const [useCustomDensity, setUseCustomDensity] = useState(false);
  const [customDensity, setCustomDensity] = useState<number>(
    CUSTOM_DENSITY_VALUES[0].value,
  );
  const density: number | undefined = useCustomDensity
    ? customDensity
    : undefined;

  const pointCloudName = useAppSelector((state) => {
    const section = selectAncestor(pointCloud, isIElementGenericDataset)(state);
    return section?.name ?? "pointcloud";
  });

  const [fileName, setFileName] = useState(pointCloudName);

  const projectId = useAppSelector(selectProjectId);

  // Temporary data to not re-allocate objects at every mouse movement
  const volumeTempData = useRef({
    obb: new OBB(),
    sizes: new Vector3(),
    planesMatrix: new Matrix4(),
  });

  const clippingBoxPlanes = useBoxControlsClippingPlanes();
  // Current desired export volume by the user in cubic meters
  const exportVolume = useMemo(() => {
    if (!clippingBoxPlanes) return 0;
    const { obb, planesMatrix, sizes } = volumeTempData.current;
    planesMatrix.fromArray(planesToArray(clippingBoxPlanes));
    obb.center.set(0, 0, 0);
    obb.rotation.identity();
    obb.halfSize.set(0.5, 0.5, 0.5);
    obb.applyMatrix4(planesMatrix);
    const { x, y, z } = obb.getSize(sizes);
    return Math.abs(x) * Math.abs(y) * Math.abs(z);
  }, [clippingBoxPlanes]);

  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const exportSubVolume = useCallback(async () => {
    if (!chosenFormat) {
      handleErrorWithToast({ title: "No format selected.", error: "" });
      return;
    }

    if (fileName.length === 0) {
      handleErrorWithToast({ title: "No file name provided.", error: "" });
      return;
    }

    if (!canExport(chosenFormat, density, exportVolume)) {
      handleErrorWithToast({
        title: `The current volume is too big for the ${chosenFormat.displayName} format.`,
        error: "",
      });
      return;
    }

    if (!clippingBoxPlanes) {
      handleErrorWithToast({
        title: "The selection is too small or contains no points.",
        error: "",
      });
      return;
    }

    if (!projectId || !formats) {
      handleErrorWithToast({
        title: "An unknown error occurred.",
        error: "",
      });
      return;
    }

    Analytics.track<ExportPointCloudProperties>(EventType.exportPointCloud, {
      hasEditedName: fileName !== pointCloudName,
      format: chosenFormat.name,
      density,
    });

    try {
      onChangeIsExportInProgress(true);
      // Convert the PointCloud Y-Up column-major world transform to Z-Up row-major
      const transform = new Matrix4()
        .fromArray(worldMatrixYUp)
        .premultiply(new Matrix4().makeRotationX(Math.PI / 2))
        .transpose();

      const {
        data: { userId },
      } = await coreApiClient.getLoggedInUser();

      await pointCloudApiClient.exportSubVolume({
        userId,
        pointCloudId: pointCloud.id,
        projectId,
        format: chosenFormat.name,
        fileName,
        boundingBox: planesToArray(clippingBoxPlanes),
        transformation: transform.toArray(),
        // Converting density from mm to m before sending it to the backend
        minimumPointSpacing: density ? density / 1000 : undefined,
      });
      openToast({
        title: "Preparing data for export",
        message:
          "Check progress status by expanding the cloud activity section.",
      });
      returnToPreviousMode(store, dispatch);
    } catch {
      handleErrorWithToast({
        title: "Point cloud export failed",
        error: "Something went wrong. Try again later.",
      });
    }
    onChangeIsExportInProgress(false);
  }, [
    chosenFormat,
    fileName,
    density,
    exportVolume,
    clippingBoxPlanes,
    projectId,
    formats,
    pointCloudName,
    onChangeIsExportInProgress,
    openToast,
    worldMatrixYUp,
    coreApiClient,
    pointCloudApiClient,
    pointCloud.id,
    store,
    dispatch,
    handleErrorWithToast,
  ]);

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack gap={2}>
        <Stack
          gap={1}
          sx={{ pointerEvents: isExportInProgress ? "none" : "auto" }}
        >
          <FaroText variant="bodyM">Select the options for the export</FaroText>
          <FaroText variant="labelM">File export name</FaroText>
          <TextField
            placeholder="File name"
            fullWidth
            text={fileName}
            onTextChanged={setFileName}
            error={fileName.length === 0 ? "Invalid name" : undefined}
          />
          <FaroText variant="labelM">File format</FaroText>
          <FaroRadioGroup
            value={chosenFormat?.name ?? "undefined"}
            disabled={isExportInProgress}
            onChange={(v) =>
              setChosenFormat(formats?.find((f) => f.name === v.target.value))
            }
          >
            {formats?.map((format) => {
              const disabled = !canExport(format, density, exportVolume);
              return (
                <FormControlLabel
                  key={format.name}
                  value={format.name}
                  disabled={disabled || isExportInProgress}
                  control={<FaroRadio />}
                  labelPlacement="end"
                  label={
                    <ExportLabel
                      format={format}
                      density={density ?? DEFAULT_DENSITY_VALUE}
                      exportVolume={exportVolume}
                    />
                  }
                  aria-label={format.displayName}
                  sx={{
                    m: 0,
                    ".MuiFormControlLabel-label": {
                      width: "100%",
                    },
                  }}
                />
              );
            })}
          </FaroRadioGroup>
        </Stack>
        <ResolutionSelector
          shouldUseCustomDensity={useCustomDensity}
          onToggleCustomDensity={setUseCustomDensity}
          density={customDensity}
          onDensityChanged={setCustomDensity}
          disabled={isExportInProgress}
        />
      </Stack>

      <FaroButton
        onClick={exportSubVolume}
        isLoading={isExportInProgress}
        aria-label="start point cloud export"
      >
        Export
      </FaroButton>
    </Stack>
  );
}

type ResolutionSelectorProps = {
  /** True if the user want to export using a custom density */
  shouldUseCustomDensity: boolean;

  /** Called when the user opt-in or opt-out to use a custom density export value */
  onToggleCustomDensity(shouldUseCustom: boolean): void;

  /** The custom density value to use */
  density: number;

  /** Called when the user changes the custom density value */
  onDensityChanged(density: number): void;

  /** True to disable this component */
  disabled?: boolean;
};

function ResolutionSelector({
  shouldUseCustomDensity,
  onToggleCustomDensity,
  density,
  onDensityChanged,
  disabled,
}: ResolutionSelectorProps): JSX.Element {
  return (
    <Stack>
      <FaroText variant="heading14">Minimum distance between points</FaroText>
      <Stack direction="row" sx={{ my: 1, mx: 0, gap: 1 }}>
        <FaroChipToggle
          label="Original"
          selected={!shouldUseCustomDensity}
          disabled={disabled}
          onClick={() => onToggleCustomDensity(false)}
        />
        <FaroChipToggle
          label="Custom"
          selected={shouldUseCustomDensity}
          disabled={disabled}
          onClick={() => onToggleCustomDensity(true)}
        />
      </Stack>
      <Stack
        sx={{
          visibility: shouldUseCustomDensity ? "visible" : "hidden",
        }}
      >
        <FaroSlider
          disabled={disabled}
          marks={CUSTOM_DENSITY_VALUES}
          step={null}
          min={CUSTOM_DENSITY_VALUES[0].value}
          max={CUSTOM_DENSITY_VALUES[CUSTOM_DENSITY_VALUES.length - 1].value}
          value={density}
          onChange={(_, value) => {
            if (typeof value === "number") {
              onDensityChanged(value);
            }
          }}
          valueLabelDisplay="auto"
        />
        <Stack direction="row" justifyContent="space-between">
          <FaroText variant="placeholder">2 mm</FaroText>
          <FaroText variant="placeholder">64 mm</FaroText>
        </Stack>
      </Stack>
    </Stack>
  );
}

type ExportLabelProps = {
  /** Format for the export */
  format: PointCloudFormat;

  /** Volume to export */
  exportVolume: number;

  /** Current selected export density in millimeters between points */
  density: number;
};

/**
 * @returns the label for the radio selection, with an additional warning message if needed
 */
function ExportLabel({
  format,
  exportVolume,
  density,
}: ExportLabelProps): JSX.Element {
  if (canExport(format, density, exportVolume)) {
    return <FaroText variant="bodyL">{format.displayName}</FaroText>;
  }

  const limit = computeExportLimit(format, density);
  const current = Math.ceil(exportVolume);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <FaroText variant="bodyL" sx={{ opacity: 0.5 }}>
        <TranslateVar name="exportFormat">{format.displayName}</TranslateVar>
      </FaroText>
      <HelpPopover
        title={`${format.displayName} unavailable`}
        variant="warning"
        description={
          <Stack direction="column" gap={1}>
            <FaroText variant="bodyL" color={neutral[300]}>
              The current selected volume of{" "}
              <TranslateVar name="currentVolume">{current}</TranslateVar> m
              {"\u00B3"} is higher than the exportable volume of{" "}
              <TranslateVar name="limit">{limit}</TranslateVar> {"m\u00B3"}.
              <br />
            </FaroText>
            <FaroText variant="bodyL" color={neutral[300]}>
              To resolve the issue, increase the minimum distance between points
              or reduce the volume to export.
            </FaroText>
          </Stack>
        }
      />
    </Stack>
  );
}
