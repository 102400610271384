import { QualityStatus } from "@/registration-tools/utils/metrics";
import {
  FaroButton,
  FaroButtonSizes,
  FaroPopover,
  FaroSvgIcon,
  FaroText,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { ReactComponent as RegistrationQualityIndicatorGood } from "../icons/registration-quality-indicator-good.svg";
import { ReactComponent as RegistrationQualityIndicatorMedium } from "../icons/registration-quality-indicator-medium.svg";
import { ReactComponent as RegistrationQualityIndicatorPoor } from "../icons/registration-quality-indicator-poor.svg";
import { ReactComponent as VectorSvg } from "../icons/vector.svg";

const OVERALL_QUALITY_STATUS_GOOD =
  "The overall scan alignment is precise which ensures accurate measurements in the published data.";
const OVERALL_QUALITY_STATUS_MEDIUM =
  "The scan alignment is okay, but there are issues which might affect measuring accuracy. Review and consider editing if necessary.";
const OVERALL_QUALITY_STATUS_POOR =
  "The scan alignment has significant issues. Please review, edit and fix.";
const OVERALL_QUALITY_STATUS_UNKNOWN =
  "The registration is not yet completed. Edit and refine to calculate quality information.";
const OVERALL_QUALITY_STATUS_UNKNOWN_SCENE =
  "The registration was completed in SCENE, please check quality information there.";
const CONNECTION_QUALITY_STATUS_GOOD =
  "The scans are well-aligned with a small point distance and high overlap. The connection is strong and reliable.";
const CONNECTION_QUALITY_STATUS_MEDIUM =
  "The scans are moderately aligned, but some deviations exist. The connection is usable but may need a review.";
const CONNECTION_QUALITY_STATUS_POOR =
  "The scans are poorly aligned. The connection is weak and may affect accuracy.";
const OVERALL_QUALITY_TITLE = "Total registration quality";
const OVERALL_QUALITY_TITLE_UNKNOWN = "Registration quality unavailable";
const CONNECTION_QUALITY_TITLE = "Pairwise registration quality";
const CLUSTER_BUTTON_TEXT = "Show detailed report";
const CONNECTION_BUTTON_TEXT = "Show details";

/** Scope to publish the quality, either for the root cluster or for a single connection  */
export enum QualityScope {
  CLUSTER = "CLUSTER",
  CONNECTION = "CONNECTION",
}

export type QualityPopoverProps = {
  /** Scope to publish the quality for */
  scope: QualityScope;
  /** Registration quality */
  quality: QualityStatus;
  /** The anchor element to tag the pop over */
  anchorEl: HTMLElement | null;
  /** Is the brief registration quality popover open */
  isOpen: boolean;
  /** Call back when the pop over is closed */
  closePopover(): void;
  /** Call back when detailed registration quality information has to be shown */
  onDetailsButtonClick(): void;
  /** Is the registration done in scene*/
  isSceneRegistered?: boolean;
  /** Scan names of the connection */
  connectionScans?: ConnectionScans;
};

/** Names of the scans in a connection */
type ConnectionScans = {
  sourceScanName: string;
  targetScanName: string;
};

/**
 * @returns A popover the displays brief registration report
 */
export function QualityPopover({
  quality,
  scope,
  anchorEl,
  isOpen,
  isSceneRegistered,
  onDetailsButtonClick,
  closePopover,
  connectionScans,
}: QualityPopoverProps): JSX.Element {
  const { title, description, buttonText } = useMemo(() => {
    let title = OVERALL_QUALITY_TITLE;
    let description = OVERALL_QUALITY_STATUS_UNKNOWN;
    let buttonText = CLUSTER_BUTTON_TEXT;
    if (scope === QualityScope.CLUSTER) {
      switch (quality) {
        case QualityStatus.GOOD:
          description = OVERALL_QUALITY_STATUS_GOOD;
          break;
        case QualityStatus.MEDIUM:
          description = OVERALL_QUALITY_STATUS_MEDIUM;
          break;
        case QualityStatus.POOR:
          description = OVERALL_QUALITY_STATUS_POOR;
          break;
        default:
          description = isSceneRegistered
            ? OVERALL_QUALITY_STATUS_UNKNOWN_SCENE
            : OVERALL_QUALITY_STATUS_UNKNOWN;
          title = OVERALL_QUALITY_TITLE_UNKNOWN;
      }
    } else {
      title = CONNECTION_QUALITY_TITLE;
      buttonText = CONNECTION_BUTTON_TEXT;
      switch (quality) {
        case QualityStatus.GOOD:
          description = CONNECTION_QUALITY_STATUS_GOOD;
          break;
        case QualityStatus.MEDIUM:
          description = CONNECTION_QUALITY_STATUS_MEDIUM;
          break;
        case QualityStatus.POOR:
          description = CONNECTION_QUALITY_STATUS_POOR;
          break;
      }
    }
    return { title, description, buttonText };
  }, [scope, quality, isSceneRegistered]);

  return (
    <FaroPopover
      title={title}
      anchorEl={anchorEl}
      placement="bottom"
      open={isOpen}
      onClose={closePopover}
      disablePortal
      dark
    >
      <Stack direction="column" gap={1.5} alignItems="center">
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-around"
        >
          <Stack
            direction="column"
            gap={0.5}
            alignItems="center"
            sx={{ opacity: quality === QualityStatus.POOR ? "100%" : "40%" }}
            aria-label="Quality Indicator Poor"
          >
            <FaroSvgIcon
              source={RegistrationQualityIndicatorPoor}
              sx={{
                width: "40px",
                height: "40px",
              }}
            />
            <FaroText variant="labelM" dark>
              {" "}
              POOR
            </FaroText>
          </Stack>

          <FaroSvgIcon source={VectorSvg} />
          <Stack
            direction="column"
            gap={0.5}
            alignItems="center"
            sx={{ opacity: quality === QualityStatus.MEDIUM ? "100%" : "40%" }}
          >
            <FaroSvgIcon
              source={RegistrationQualityIndicatorMedium}
              sx={{
                width: "40px",
                height: "40px",
              }}
              aria-label="Quality Indicator Medium"
            />
            <FaroText variant="labelM" dark>
              {" "}
              MEDIUM
            </FaroText>
          </Stack>

          <FaroSvgIcon source={VectorSvg} />
          <Stack
            direction="column"
            gap={0.5}
            alignItems="center"
            sx={{ opacity: quality === QualityStatus.GOOD ? "100%" : "40%" }}
          >
            <FaroSvgIcon
              source={RegistrationQualityIndicatorGood}
              sx={{
                width: "40px",
                height: "40px",
              }}
              aria-label="Quality Indicator Good"
            />
            <FaroText variant="labelM" dark>
              {" "}
              GOOD
            </FaroText>
          </Stack>
        </Stack>
        {scope === QualityScope.CONNECTION && connectionScans !== undefined && (
          <FaroText
            aria-label="registered connection name"
            variant="labelM"
            dark
          >
            <TranslateVar name="source-scan">
              {connectionScans.sourceScanName}
            </TranslateVar>{" "}
            -{" "}
            <TranslateVar name="target-scan">
              {connectionScans.targetScanName}
            </TranslateVar>
          </FaroText>
        )}
        <FaroText variant="bodyS" dark>
          {description}
        </FaroText>
        {quality !== QualityStatus.UNKNOWN && (
          <FaroButton
            size={FaroButtonSizes.s}
            aria-label="detailed quality report"
            onClick={() => {
              onDetailsButtonClick();
            }}
          >
            {" "}
            {buttonText}{" "}
          </FaroButton>
        )}
      </Stack>
    </FaroPopover>
  );
}
