import { GUID } from "@faro-lotv/foundation";
import { create } from "zustand";

type TagsManagementContext = {
  /** The id of the current active area */
  activeAreaId: GUID | undefined;
  /** A callback to set the active area id in the context */
  setActiveAreaId(id: GUID | undefined): void;
  /** The list of scans/rooms selected by the user */
  selectedIds: GUID[];
  /** A callback to select exactly one scan/room */
  selectId(selectedId: GUID): void;
  /** A callback to select multiple scans/rooms at the same time */
  multiSelectIds(selectedIds: GUID[]): void;
  /** A callback to toggle the selection state of a scans/rooms */
  toggleSelectedId(selectedId: GUID): void;
};

export const useTagsManagementContext = create<TagsManagementContext>()(
  (set, getState) => ({
    activeAreaId: undefined,
    setActiveAreaId: (id: GUID | undefined) => set({ activeAreaId: id }),
    selectedIds: [],
    selectId: (selectedId: GUID) => set({ selectedIds: [selectedId] }),
    multiSelectIds: (selectedIds: GUID[]) => set({ selectedIds }),
    toggleSelectedId: (selectedId: GUID) => {
      const state = getState();
      const selectedIndex = state.selectedIds.findIndex(
        (id) => selectedId === id,
      );
      if (selectedIndex >= 0) {
        const newSelectedIds = [...state.selectedIds];
        newSelectedIds.splice(selectedIndex, 1);
        set({ selectedIds: newSelectedIds });
      } else {
        set({ selectedIds: [...state.selectedIds, selectedId] });
      }
    },
  }),
);
