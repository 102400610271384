import { PointCloudObject } from "@/object-cache";
import { GUID } from "@faro-lotv/ielement-types";
import {
  MultiCloudPointBudgetManager,
  MultiCloudVisibleNodesStrategy,
  VisibleNodesStrategy,
} from "@faro-lotv/lotv";
import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";

/**
 * The maximum number of points for all point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_TOTAL_POINTS = 4_000_000;

/**
 * The default maximum number of points to download at the same time for both point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_NODES_TO_DOWNLOAD_AT_ONCE = 6;

/**
 * Handler to manage multiple point clouds for efficient rendering.
 * It takes a maximum point budget and then distributes it among the point clouds
 * depending on how much of them is visible in the camera view.
 *
 * @param pointCloudObjects The point cloud objects to manage the point budget for.
 * @param maxPointsInGpu The maximum number of points to store in the GPU for each cloud.
 *  We will have `maxPointsInGpu * pointCloudObjects.length` points in the GPU in total,
 *  but only `maxPointsInGpu` points will be rendered in total.
 * @param maxNodesToDownloadAtOnce The maximum number of nodes to download at the same time for all point clouds.
 */
export function useMultiCloudPointBudgetManager(
  pointCloudObjects: PointCloudObject[],
  maxPointsInGpu: number = MAX_TOTAL_POINTS,
  maxNodesToDownloadAtOnce: number = MAX_NODES_TO_DOWNLOAD_AT_ONCE,
): void {
  const previousPointCloudSettings = useRef(
    new Map<GUID, PointCloudSettings>(),
  );

  const [pointBudgetManager] = useState(
    () => new MultiCloudPointBudgetManager(maxPointsInGpu),
  );

  // Set the maximum points per GPU on each point cloud
  useEffect(() => {
    // Save the previous settings
    for (const pointCloudObject of pointCloudObjects) {
      const { id } = pointCloudObject.iElement;

      // Do not overwrite, we want to restore the earliest settings
      if (!previousPointCloudSettings.current.has(id)) {
        previousPointCloudSettings.current.set(id, {
          visibleNodesStrategy: pointCloudObject.visibleNodesStrategy,
          maxNodesToDownloadAtOnce:
            pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce,
        });
      }
    }

    // This is currently not ideal, because it doesn't distribute download resources according to need, nor
    // does it limit downloads when there is a lot of point clouds.
    // Currently at least one download per point cloud is required for the download logic to not stall.
    // see https://faro01.atlassian.net/browse/NRT-1309
    const maxNodesToDownloadPerCloud = Math.max(
      1,
      Math.floor(maxNodesToDownloadAtOnce / pointCloudObjects.length),
    );

    // Configure the settings we need
    for (const pointCloudObject of pointCloudObjects) {
      const newStrategy = new MultiCloudVisibleNodesStrategy(
        pointBudgetManager,
        pointCloudObject.visibleNodesStrategy,
      );
      pointBudgetManager.addStrategy(newStrategy);
      pointCloudObject.visibleNodesStrategy = newStrategy;
      pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce =
        maxNodesToDownloadPerCloud;
    }

    // Restore previous settings
    return () => {
      for (const pointCloudObject of pointCloudObjects) {
        // The ref doesn't point to a node, so we can safely use `current` here again
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const previousSettings = previousPointCloudSettings.current.get(
          pointCloudObject.iElement.id,
        );

        if (previousSettings) {
          if (
            pointCloudObject.visibleNodesStrategy instanceof
            MultiCloudVisibleNodesStrategy
          ) {
            pointBudgetManager.removeStrategy(
              pointCloudObject.visibleNodesStrategy,
            );
          }
          pointCloudObject.visibleNodesStrategy =
            previousSettings.visibleNodesStrategy;
          pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce =
            previousSettings.maxNodesToDownloadAtOnce;
        }
      }
    };
  }, [pointCloudObjects, pointBudgetManager, maxNodesToDownloadAtOnce]);

  // Update the point allocation per cloud
  useFrame(() => {
    pointBudgetManager.update();
  });
}

/** The point cloud settings that we overwrite in the hook. */
type PointCloudSettings = {
  visibleNodesStrategy: VisibleNodesStrategy;
  maxNodesToDownloadAtOnce: number;
};
