import {
  ElementIcon,
  ElementIconType,
  useElementIcon,
} from "@/components/ui/icons";
import {
  TREE_NODE_HEIGHT,
  TreeNode,
  TreeNodeProps,
} from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroChipList,
  FaroChipTag,
  FaroText,
  neutral,
  NO_TRANSLATE_CLASS,
  NoTranslate,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement, TreeData } from "@faro-lotv/project-source";
import { Box, Stack } from "@mui/system";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RowRendererProps, Tree, TreeApi } from "react-arborist";
import { TreeProps } from "react-arborist/dist/module/types/tree-props";
import { useTagsManagementContext } from "./tags-management-context";
import { TagsManagementScene } from "./tags-management-selector";
import { useRemoveTagFromScan } from "./use-remove-tag-from-scan";

/** The fake id used to identify the dataset folder in the tree */
const DATA_SESSION_FOLDER_ID = "data-session-folder-id";

/** The fake id used to identify the 360s folder in the tree */
const PANO_FOLDER_ID = "pano-folder-id";

type TagsManagementTreeProps = {
  /** The list of elements that make up the scene for the current selected area */
  currentScene: TagsManagementScene;
  /** A flag specifying if the scene is still loading */
  isLoading: boolean;
};

/** @returns The project tree shown in the tags management mode */
export function TagsManagementTree({
  currentScene,
  isLoading,
}: TagsManagementTreeProps): JSX.Element | null {
  return (
    <TreeWrapper>
      <TagsManagementTreeBase
        currentScene={currentScene}
        isLoading={isLoading}
      />
    </TreeWrapper>
  );
}

/** The custom data of the nodes of the tree */
type TagsManagementTreeData = Omit<TreeData, "children"> & {
  /** The icon type of the node */
  icon?: ElementIconType;
  /** The children nodes of the node */
  children: TagsManagementTreeData[] | null;
};

/** @returns The tree showing the list of scans and 360s available in the current scene */
function TagsManagementTreeBase({
  height,
  currentScene,
  isLoading,
}: Pick<TreeProps<TagsManagementTreeData>, "height"> &
  TagsManagementTreeProps): JSX.Element | null {
  const tree = useAppSelector(selectTagsManagementTree(currentScene), isEqual);
  const { selectedIds, multiSelectIds } = useTagsManagementContext();

  const [treeRef, setTreeRef] = useState<
    TreeApi<TagsManagementTreeData> | null | undefined
  >(null);
  // Sync the selection state with the tree
  useEffect(() => {
    const tree = treeRef;
    if (!tree) return;

    // Prevent infinite loop due to state sync
    if (isEqual(new Set(selectedIds), tree.selectedIds)) return;

    const lastSelected = selectedIds.at(-1);
    tree.focus(lastSelected ?? null);
    // Update the tree after focusing so that the list of visible nodes changes. Otherwise,
    // if the parent is collapsed, selecting a child does not work as expected because
    // the node is not the in the list of visible ones
    tree.update(tree.props);

    // Update the selection
    tree.setSelection({
      ids: selectedIds,
      anchor: selectedIds.at(0) ?? null,
      mostRecent: lastSelected ?? null,
    });
  }, [selectedIds, treeRef]);

  if (isLoading) {
    return null;
  }

  if (!tree.length) {
    return (
      <FaroText variant="heading12">
        No 360° or scans found in this area.
      </FaroText>
    );
  }

  return (
    <NoTranslate sx={{ height: "100%" }}>
      <Tree<TagsManagementTreeData>
        ref={setTreeRef}
        data={tree}
        openByDefault={false}
        disableDrag
        disableDrop
        width="100%"
        rowHeight={TREE_NODE_HEIGHT}
        indent={18}
        height={height}
        renderRow={TagsManagementTreeRow}
        onSelect={(nodes) => {
          const selectedIds = nodes.map((node) => node.data.id);
          multiSelectIds(selectedIds);
        }}
      >
        {TagsManagementTreeNode}
      </Tree>
    </NoTranslate>
  );
}

/**
 * @returns The component to pick the right UI element based on the tree node type
 */
function TagsManagementTreeNode({
  node,
  style,
}: TreeNodeProps<TagsManagementTreeData>): JSX.Element {
  if (node.id === DATA_SESSION_FOLDER_ID || node.id === PANO_FOLDER_ID) {
    return (
      <FaroText
        variant="heading12"
        className={NO_TRANSLATE_CLASS}
        // The lineHeight is slightly increased to keep the section header
        // closer to the first element of the list
        sx={{ ...style, lineHeight: `${TREE_NODE_HEIGHT + 8}px` }}
      >
        {node.data.label}
      </FaroText>
    );
  }

  return <TagsManagementTreeIElementNode node={node} style={style} />;
}

function TagsManagementTreeRow({
  node,
  innerRef,
  attrs,
  children,
}: RowRendererProps<TagsManagementTreeData>): JSX.Element {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      // If clicking on a dataset without the control key, do nothing
      if (node.children && !(e.ctrlKey || e.metaKey)) {
        return;
      }
      // If clicking on a dataset with the control key, select all children
      if (node.children) {
        for (const child of node.children) {
          child.selectMulti();
        }
        return;
      }

      if (e.ctrlKey || e.metaKey) {
        if (node.isSelected) {
          node.deselect();
        } else {
          node.selectMulti();
        }
      } else if (e.shiftKey) {
        node.selectContiguous();
      } else {
        node.select();
        node.activate();
      }

      // Deselect all datasets, we don't need them to be highlighted in the tree view
      for (const selectedNode of node.tree.selectedNodes) {
        if (selectedNode.children) {
          selectedNode.deselect();
        }
      }
    },
    [node],
  );

  return (
    <Box component="div" ref={innerRef} {...attrs} onClick={onClick}>
      {children}
    </Box>
  );
}

/**
 * @returns The UI component used to render the scans and 360s in the tree
 */
function TagsManagementTreeIElementNode({
  node,
  style,
}: TreeNodeProps<TagsManagementTreeData>): JSX.Element {
  const icon = useElementIcon(node.data.element, node.data.directParent);
  const element = useAppSelector(selectIElement(node.data.element?.id));

  const [tagRemoved, setTagRemoved] = useState<GUID>();

  const removeTag = useRemoveTagFromScan(element);
  const chips = useMemo(
    () =>
      element?.labels?.map((label) => (
        <FaroChipTag
          label={label.name}
          key={label.id}
          showSpinner={tagRemoved === label.id}
          disabled={!!tagRemoved}
          onDelete={() => {
            setTagRemoved(label.id);
            removeTag(label).finally(() => setTagRemoved(undefined));
          }}
        />
      )) ?? [],
    [element?.labels, removeTag, tagRemoved],
  );

  return (
    <Box component="div" style={{ ...style, height: "100%" }}>
      <TreeNode<TagsManagementTreeData> node={node} collapseIcon>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            overflow: "hidden",
            width: "100%",
          }}
          gap="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="6px"
            sx={{ maxWidth: chips.length > 0 ? "80%" : "100%", flexGrow: 1 }}
          >
            <ElementIcon
              icon={node.data.icon ?? icon}
              sx={{ fontSize: "1.125em" }}
            />
            <TruncatedFaroText
              variant="bodyM"
              tooltip={<NoTranslate>{node.data.label}</NoTranslate>}
              sx={{ color: node.isSelected ? neutral[0] : neutral[800] }}
            >
              {node.data.label}
            </TruncatedFaroText>
          </Stack>
          <FaroChipList
            chips={chips}
            sx={{ minWidth: 0, pr: 1 }}
            showSingleChip={false}
            reverse
          />
        </Stack>
      </TreeNode>
    </Box>
  );
}

/**
 * @returns A selector to collect the list of nodes of tree
 * @param currentScene The current scene elements
 */
function selectTagsManagementTree(currentScene: TagsManagementScene) {
  return (state: RootState) => {
    const nodes: TagsManagementTreeData[] = [];

    // Collect scans
    const dataSessionsIds = Object.keys(currentScene.scans);
    if (dataSessionsIds.length > 0) {
      nodes.push({
        id: DATA_SESSION_FOLDER_ID,
        label: "Datasets",
        children: null,
      });

      for (const dataSessionId of dataSessionsIds) {
        const dataSession = selectIElement(dataSessionId)(state);
        if (!dataSession) continue;

        const node: TagsManagementTreeData = {
          id: dataSession.id,
          label: dataSession.name,
          element: dataSession,
          children: [],
          directParent: dataSession.parentId
            ? selectIElement(dataSession.parentId)(state)
            : undefined,
        };
        nodes.push(node);

        for (const scan of currentScene.scans[dataSessionId]) {
          node.children?.push({
            id: scan.id,
            label: scan.name,
            element: scan,
            children: null,
            directParent: scan.parentId
              ? selectIElement(scan.parentId)(state)
              : undefined,
            icon: ElementIconType.ScanIcon,
          });
        }
        node.children?.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" }),
        );
      }
    }

    // Collect rooms
    if (currentScene.rooms.length > 0) {
      nodes.push({
        id: PANO_FOLDER_ID,
        label: "360° Photo",
        children: null,
      });

      for (const room of currentScene.rooms) {
        nodes.push({
          id: room.id,
          label: room.name,
          element: room,
          children: null,
          directParent: room.parentId
            ? selectIElement(room.parentId)(state)
            : undefined,
          icon: ElementIconType.PanoramaIcon,
        });
      }
    }

    return nodes;
  };
}
