import { LanguageSelector } from "@/components/ui/language-selector";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import { runtimeConfig } from "@/runtime-config";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { APP_VERSION } from "@/utils/env-constants";
import { HeaderBar, useLocalStorage } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import {
  IElement,
  IElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { ToolBreadcrumbs } from "../components/ui/tool-breadcrumbs";
import { Alignment3DView } from "./alignment-3d-view";
import { CloudToSheetAlignmentProgressBar } from "./alignment-progress-bar/cloud-to-sheet-alignment-progress-bar";
import {
  EventType,
  OpenQuickHelpEventProperties,
} from "./analytics/analytics-events";
import { useIsScaleEnabled } from "./hooks/use-is-scale-enabled";
import { QuickHelp } from "./quick-help/quick-help";
import {
  selectActiveStep,
  selectActiveStepId,
  selectAlignmentArea,
  selectCloudToAlign,
  selectElementToAlignTransform,
  selectIsAlignmentToolBusy,
} from "./store/alignment-selectors";
import { AlignmentTransform } from "./store/alignment-slice";
import { selectIsQuickHelpOpen } from "./store/alignment-ui/alignment-ui-selector";
import { setIsQuickHelpOpen } from "./store/alignment-ui/alignment-ui-slice";

interface Props {
  /** A callback that is called when the user confirms the alignment */
  onFinishAlignment?(
    sheet: IElement,
    element: IElementGenericPointCloudStream,
    elementTransform: AlignmentTransform,
  ): void;
  /** A callback that is called when the user tries to redirect back to the viewer */
  onRedirectToViewerClicked?(): void;
}

/**
 * @returns The main UI layout for the Alignment Tool app
 */
export function AlignmentToolUi({
  onFinishAlignment,
  onRedirectToViewerClicked,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const [isFirstTimeInAlignment, setIsFirstTimeInAlignment] = useLocalStorage(
    "isFirstTimeInAlignment",
    true,
  );

  const isQuickHelpOpen = useAppSelector(selectIsQuickHelpOpen);

  const isBusy = useAppSelector(selectIsAlignmentToolBusy);
  const activeStep = useAppSelector(selectActiveStep);
  const activeStepId = useAppSelector(selectActiveStepId);

  // reference area for selected cloud
  const alignmentArea = useAppSelector(selectAlignmentArea);
  const selectedCloud = useAppSelector(selectCloudToAlign);

  const elementTransform = useAppSelector(selectElementToAlignTransform);
  const currentUser = useAppSelector(selectCurrentUser);
  const isScaleEnabled = useIsScaleEnabled(alignmentArea);

  const { requestLogin, logout } = useAuthContext();

  const openAccountAndSecurity = useOpenAccountAndSecurity();

  useEffect(() => {
    if (isFirstTimeInAlignment) {
      // If the user is in the AlignmentTool tool for the first time, then open the quick help tool
      dispatch(setIsQuickHelpOpen(true));
    }
    // Once the user is in this component, then mark the user is not in alignment for the first time
    setIsFirstTimeInAlignment(false);
  }, [dispatch, isFirstTimeInAlignment, setIsFirstTimeInAlignment]);

  const onQuickHelpClick = useCallback(() => {
    Analytics.track<OpenQuickHelpEventProperties>(EventType.openQuickHelp, {
      via: "header bar",
    });

    dispatch(setIsQuickHelpOpen(!isQuickHelpOpen));
  }, [dispatch, isQuickHelpOpen]);

  const onAlignmentFinished = useCallback(() => {
    assert(
      alignmentArea && selectedCloud,
      "Expected alignment to be completed with an active sheet and pointcloud.",
    );
    assert(elementTransform, "Final element transformation missing.");

    onFinishAlignment?.(alignmentArea, selectedCloud, elementTransform);
  }, [alignmentArea, elementTransform, onFinishAlignment, selectedCloud]);

  return (
    <>
      {isBusy && (
        <ModalSpinner
          sx={(theme) => ({
            color: theme.palette.primary.main,
            zIndex: theme.zIndex.drawer + 1,
          })}
          open
        />
      )}

      <Stack
        sx={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <HeaderBar
          content={
            <ToolBreadcrumbs
              toolName="Alignment Tool"
              onRedirectToViewerClicked={onRedirectToViewerClicked}
            />
          }
          userMenuContent={<LanguageSelector />}
          appVersion={APP_VERSION}
          userDisplayInfo={currentUser}
          links={runtimeConfig.externalLinks}
          isQuickHelpOpen={isQuickHelpOpen}
          onQuickHelpClick={onQuickHelpClick}
          onLogInClick={requestLogin}
          onLogOutClick={logout}
          onAccountAndSecurityClick={openAccountAndSecurity}
        />

        {activeStep !== undefined && (
          <CloudToSheetAlignmentProgressBar
            onFinishAlignment={onAlignmentFinished}
          />
        )}

        <Stack
          sx={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
          direction="row"
        >
          <Alignment3DView />

          {isQuickHelpOpen &&
            activeStep !== undefined &&
            activeStepId !== undefined && (
              <QuickHelp
                activeStep={activeStep}
                onClose={() => {
                  Analytics.track(EventType.closeQuickHelp);

                  dispatch(setIsQuickHelpOpen(false));
                }}
                activeStepId={activeStepId}
                isTwoPointAlignmentActive={isScaleEnabled}
              />
            )}
        </Stack>
      </Stack>
    </>
  );
}
