import { getPickedPoint } from "@faro-lotv/app-component-toolbox";
import { PointCloud, TiledPano, TypedEvent } from "@faro-lotv/lotv";
import {
  DomEvent,
  ThreeEvent,
} from "@react-three/fiber/dist/declarations/src/core/events";
import { EventDispatcher, MOUSE, Vector2, Vector3 } from "three";
import { ToolControlsLogic } from "../tool-controls-interface";

/**
 * Define the way the next picked point should be projected on the previous picked point.
 */
export enum ProjectionType {
  // Do not project the point = the new point will be the same as the picked point
  DoNotProject = "DoNotProject",
  // Project to either the horizontal plane or vertical line from latest point (whichever is closer)
  ProjectOnHorizontalOrVertical = "ProjectOnHorizontalOrVertical",
  // Project to either the line from latest point parallel to X or Y axis, never on Z (whichever is closer)
  ProjectOnXY = "ProjectOnXY",
}

/**
 * Provides functionality for taking a polygon measurement in 3D.
 * During the measurement process, events are emitted to indicate when a measurement has started,
 * when the picked points have changed, and when a measurement has been completed.
 *
 * TODO: add tests https://faro01.atlassian.net/browse/SWEB-3911
 */
export class MultiPointMeasureControlsLogic
  extends EventDispatcher
  implements ToolControlsLogic
{
  // Current list of the points in the measurement
  #points: Vector3[] | undefined = [];

  // The "current" point = either the latest picked point (even if this point is not accepted in the measurement)
  // or the currently hovered existing point in the measurement
  #currentPoint = new Vector3();

  // The "current" point before optional projection.
  #rawCurrentPoint = new Vector3();

  // The "current" normal, if provided by the model
  #currentNormal: Vector3 | undefined = undefined;

  // The coordinate on screen in pixels of the mouse pointer hovering a point (could be slightly different from the point in measurement)
  #endPointInPixels = new Vector2();

  // The id of the IElement that is currently being measured
  // It is same for all picked points except for the case of mixed point cloud and cad model.
  // In case of mixed point cloud and cad model, this id is always the IElement id of the point cloud.
  #currentElement: string | undefined;

  // Define whether we should project the point, and how to project it.
  #projectionType: ProjectionType = ProjectionType.DoNotProject;

  /**
   * Enable or disable the projection of the new point to latest point.
   *
   * @param projectionType ProjectionType defining the way to project the mouse pointer on previous picked point,
   *  ProjectionType.DoNotProject to disable projection
   */
  enableProjection(projectionType: ProjectionType): void {
    const valueChanged = this.#projectionType !== projectionType;
    this.#projectionType = projectionType;
    if (valueChanged) {
      this.#computeCurrentPoint();
      this.onCurrentPointChanged.emit({
        point: this.#currentPoint,
        normal: this.#currentNormal,
      });
    }
  }

  // Called after first point was picked
  onMeasurementStarted = new TypedEvent<void>();

  // Called when the whole measurement is completed
  //  isClosed = true if the measurement is a closed polygon; false otherwise
  //  id = id of the IElement used for measurement (same for all picked points)
  //  isCompletedByClickFirstPoint if true, polygon is closed by click on first point
  onMeasurementCompleted = new TypedEvent<{
    isClosed: boolean;
    id: string;
    isCompletedByClickFirstPoint?: boolean;
  }>();

  // Called when the whole measurement is canceled
  onMeasurementCanceled = new TypedEvent<void>();

  // Called whenever the current point changes
  //  Parameter is the new coordinate of the current point and optional normal if provided by the model,
  //  undefined when there is no current point anymore
  onCurrentPointChanged = new TypedEvent<{
    point?: Vector3;
    normal?: Vector3;
  }>();

  // Called whenever the list of points changed (i.e. a point was added or removed, of the whole list flushed )
  onPointsChanged = new TypedEvent<void>();

  /**
   * Project the point to either the horizontal plane or vertical line from referencePoint (whichever is closer)
   *
   * @param point The point to project
   * @param referencePoint the reference point to project the point to
   * @returns The projected point
   */
  #projectPointOnHorizontalOrVertical(
    point: Vector3,
    referencePoint: Vector3,
  ): Vector3 {
    const verticalDeviation = Math.abs(point.y - referencePoint.y);
    const horizontalDeviation = Math.sqrt(
      Math.pow(point.x - referencePoint.x, 2) +
        Math.pow(point.z - referencePoint.z, 2),
    );
    if (verticalDeviation < horizontalDeviation) {
      // new point is closer from previous horizontal plane that vertical line = project on the horizontal plane
      return new Vector3(point.x, referencePoint.y, point.z);
    }
    // new point is closer from previous vertical line than horizontal plane = project on the vertical line
    return new Vector3(referencePoint.x, point.y, referencePoint.z);
  }

  /**
   * Project the point to either X or Y user axis, not Z, passing by a specific reference point (whichever is closer)
   *
   * @param point The point to project
   * @param referencePoint the reference point to project the point to
   * @returns The projected point
   */
  #projectPointOnXY(point: Vector3, referencePoint: Vector3): Vector3 {
    // User's Z axis is indeed Y internal axis
    const xDeviation = Math.abs(point.x - referencePoint.x);
    const zDeviation = Math.abs(point.z - referencePoint.z);
    if (xDeviation < zDeviation) {
      // new point is closer from x axis
      return new Vector3(referencePoint.x, point.y, point.z);
    }
    // new point is closer from z axis
    return new Vector3(point.x, point.y, referencePoint.z);
  }

  /**
   * Project the point according to active option.
   *
   * @param point The point to project
   * @param referencePoint the reference point to project the point to
   * @returns The projected point
   */
  #projectPoint(point: Vector3, referencePoint: Vector3): Vector3 {
    switch (this.#projectionType) {
      case ProjectionType.ProjectOnHorizontalOrVertical:
        return this.#projectPointOnHorizontalOrVertical(point, referencePoint);
      case ProjectionType.ProjectOnXY:
        return this.#projectPointOnXY(point, referencePoint);
      default:
        throw new Error("Invalid projection type");
    }
  }

  /**
   * Update currentPoint form current value of rawCurrentPoint.
   */
  #computeCurrentPoint(): void {
    if (
      this.#points?.length &&
      this.#projectionType !== ProjectionType.DoNotProject
    ) {
      this.#currentPoint.copy(
        this.#projectPoint(
          this.#rawCurrentPoint,
          this.#points[this.#points.length - 1],
        ),
      );
    } else {
      this.#currentPoint.copy(this.#rawCurrentPoint);
    }
  }

  /**
   * Called when the user moves the mouse above the current active model
   *
   * @param ev The mouse event that triggered this callback
   */
  pointHovered(ev: ThreeEvent<DomEvent>): void {
    getPickedPoint(ev, this.#rawCurrentPoint);
    this.#computeCurrentPoint();
    this.#endPointInPixels.set(ev.clientX, ev.clientY);

    this.#currentNormal = undefined;
    if (ev.object instanceof TiledPano) {
      // Update the normal if the model is Pano
      this.#currentNormal = ev.face?.normal.clone();
    }

    this.onCurrentPointChanged.emit({
      point: this.#currentPoint,
      normal: this.#currentNormal,
    });
  }

  /**
   * Called when the user clicks/taps on the current active model
   *
   * @param ev The mouse event that triggered this callback
   * @param iElementId The iElement that triggered this event
   */
  pointClicked(ev: ThreeEvent<DomEvent>, iElementId: string): void {
    if (ev.button !== MOUSE.LEFT) return;
    ev.stopPropagation();
    if (!this.#points) this.#points = [];

    getPickedPoint(ev, this.#rawCurrentPoint);
    this.#computeCurrentPoint();
    if (
      this.#points.length > 0 &&
      this.#currentPoint.distanceTo(this.#points[this.#points.length - 1]) <
        Number.EPSILON
    ) {
      return;
    }

    this.#points.push(this.#currentPoint.clone());
    if (ev.object instanceof PointCloud) {
      // always take the point cloud element as the current element
      this.#currentElement = iElementId;
    }
    this.onPointsChanged.emit();

    if (this.#points.length === 1) {
      this.onMeasurementStarted.emit();
      this.#currentElement = iElementId;
    }
  }

  /**
   * Complete the interaction
   *
   * @param isClosed True if the measurement is a closed polygon
   * @param isCompletedByClickFirstPoint if true, polygon is closed by click on first point
   */
  complete(isClosed: boolean, isCompletedByClickFirstPoint?: boolean): void {
    if (!this.#currentElement) return;
    this.onMeasurementCompleted.emit({
      isClosed,
      id: this.#currentElement,
      isCompletedByClickFirstPoint,
    });
    this.#points = undefined;
    this.onPointsChanged.emit();
    this.onCurrentPointChanged.emit({});
    this.#currentElement = undefined;
  }

  /**
   * Remove one point from the list of picked points
   *
   * @param index The index of the point to remove
   * @param closeCommandOnLastPoint True if the measurement should be canceled when the last point is removed
   */
  deletePoint(index: number, closeCommandOnLastPoint: boolean): void {
    if (!this.#points) return;
    if (index < 0 || index >= this.#points.length) return;

    this.#points.splice(index, 1);
    this.onPointsChanged.emit();

    if (this.#points.length === 0 && closeCommandOnLastPoint) {
      this.cancel();
    }
  }

  /**
   * Delete last point in the list of picked points if there is any.
   *
   * @returns True if a point was deleted, false otherwise
   */
  deleteLatestPoint(): boolean {
    if (!this.#points || this.#points.length === 0) return false;

    this.deletePoint(this.#points.length - 1, false);

    if (this.#points.length === 0) {
      this.#currentElement = undefined;
    }

    return true;
  }

  /**
   * Initialize the measurement
   */
  initialize(): void {
    this.#points = undefined;
    this.onPointsChanged.emit();
    this.onCurrentPointChanged.emit({});
    this.#currentElement = undefined;
  }

  /**
   * Cancels the measurement
   */
  cancel(): void {
    this.initialize();
    this.onMeasurementCanceled.emit();
  }

  /**
   * @returns The list of picked points. Undefined if the measurement has never started
   */
  get points(): Vector3[] | undefined {
    return this.#points;
  }

  /**
   * @returns true if the measurement is started or live
   */
  isLiveMeasure(): boolean {
    return !!this.#points;
  }

  /**
   * @returns The 2D coordinates in pixels of the point on which the mouse hovering
   */
  get pointCoordinates(): Vector2 {
    return this.#endPointInPixels;
  }
}
