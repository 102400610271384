import { matrix4ToAlignmentTransform } from "@/alignment-tool/utils/alignment-transform";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { PlaceholderPreview } from "@/components/r3f/utils/placeholder-preview";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectExclusiveModeCompletionAction } from "@/store/ui/ui-selectors";
import {
  Map2DControls,
  TwoPointAlignment,
  TwoPointAlignmentActions,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElementWorldPosition,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericVideoRecording,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useCallback, useMemo, useRef, useState } from "react";
import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import {
  PathAlignmentTool,
  usePathAlignmentContext,
} from "./path-alignment-mode-context";
import { PathAlignmentSinglePoint } from "./path-alignment-single-point";

/** The near plane distance from the camera, in meters */
const NEAR_PLANE_DISTANCE = 0.1;

/**
 * @returns The current sheet and the corresponding odometric path
 */
export function PathAlignmentModeScene(): JSX.Element {
  const camera = useThree((s) => s.camera);
  useReproportionCamera(camera);

  const { referenceElement, activeSheets } = useCurrentScene();
  const videoRecording = useAppSelector(
    selectChildDepthFirst(referenceElement, isIElementGenericVideoRecording, 1),
  );

  // Use the first visible layer during alignment.
  const sheetForAlignment = activeSheets[0];

  assert(
    sheetForAlignment,
    "Path Alignment Mode requires a valid sheet image to align the video recording to",
  );
  assert(
    videoRecording,
    "Path Alignment Mode requires a data session with a video recording as the reference element",
  );

  const position = useAppSelector(
    selectIElementWorldPosition(sheetForAlignment.id),
  );

  // Get sheet from the object cache or suspend loading it
  const sheet = useCached3DObject(sheetForAlignment);

  const { activeTool, pose, positions, setPose } = usePathAlignmentContext();

  const processing = !!useAppSelector(selectExclusiveModeCompletionAction);

  const placeholders = useAppSelector(
    selectChildrenDepthFirst(videoRecording, isIElementImg360),
    isEqual,
  );

  const [hoveredPlaceholderIndex, setHoveredPlaceholderIndex] =
    useState<number>();

  const hoveredPlaceholderPosition = useMemo(() => {
    if (hoveredPlaceholderIndex === undefined) return;
    const pathPose = new Matrix4().fromArray(pose);

    return positions[hoveredPlaceholderIndex].clone().applyMatrix4(pathPose);
  }, [hoveredPlaceholderIndex, pose, positions]);

  const [controlsDisabled, setControlsDisabled] = useState(false);

  const transform = useMemo(
    () => matrix4ToAlignmentTransform(new Matrix4().fromArray(pose)),
    [pose],
  );

  const box = useMemo(
    () =>
      new Box3()
        .setFromPoints([...positions, new Vector3().fromArray(position)])
        .expandByScalar(1),
    [positions, position],
  );
  // Based on the bounding box containing the positions of the path,
  // this hook computes the camera frustum and position that allow to frame everything
  // without frustum culling. Since the two points alignment does not move the data in the Y
  // direction and we are using an orthographic camera, this is only needed once.
  const cameraAltitude = useMemo(() => {
    camera.position.y = box.max.y + NEAR_PLANE_DISTANCE;
    camera.near = NEAR_PLANE_DISTANCE;
    camera.far = camera.position.y - box.min.y + NEAR_PLANE_DISTANCE;
    camera.updateMatrixWorld(true);
    camera.updateProjectionMatrix();
    return camera.position.y;
  }, [camera, box]);

  const onTransformChanged = useCallback(
    (p: Vector3, q: Quaternion, s: Vector3) => {
      setPose(new Matrix4().compose(p, q, s).toArray());
    },
    [setPose],
  );

  const actions = useRef<TwoPointAlignmentActions>(null);

  const onPlaceholderHovered = useCallback((index?: number) => {
    setHoveredPlaceholderIndex(index);
  }, []);

  return (
    <>
      <SheetRenderer sheet={sheet} />
      <TwoPointAlignment
        enabled={activeTool === PathAlignmentTool.alignPath && !processing}
        onTransformChanged={onTransformChanged}
        onPointerDown={() => setControlsDisabled(true)}
        onPointerUp={() => setControlsDisabled(false)}
        actions={actions}
        cameraAltitude={cameraAltitude}
        {...transform}
      >
        <PathAlignmentSinglePoint
          enabled={activeTool === PathAlignmentTool.adjustPath && !processing}
          onDragging={setControlsDisabled}
          onPlaceholderClick={(position) => {
            if (activeTool === PathAlignmentTool.alignPath) {
              actions.current?.tryDropPin(
                position.clone().setY(camera.position.y - camera.near),
              );
            }
          }}
          onPlaceholderHovered={onPlaceholderHovered}
        />
      </TwoPointAlignment>
      <PlaceholderPreview
        placeholder={
          hoveredPlaceholderIndex !== undefined && !controlsDisabled
            ? placeholders[hoveredPlaceholderIndex]
            : undefined
        }
        position={hoveredPlaceholderPosition}
      />
      <Map2DControls
        camera={camera}
        referencePlaneHeight={position[1]}
        enabled={!controlsDisabled}
      />
    </>
  );
}
