import { BetaBadge } from "@/components/ui/beta-badge";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { Captur3dFloorplanGenerationTask } from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import { FaroText, NO_TRANSLATE_CLASS, NoTranslate } from "@faro-lotv/flat-ui";
import {
  GUID,
  IElement,
  IElementAttachment,
  isIElementAttachment,
  isIElementGenericDataset,
  isIElementSectionDataSession,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  createSignedUrlForIElementUri,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  isBackgroundTaskActive,
  ProjectApi,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { createSelector } from "@reduxjs/toolkit";
import { CardProgress } from "../../card-progress";
import {
  CardAction,
  GenericCardLayout,
} from "../../layouts/generic-card-layout";

type FloorplanGenerationCardProps = {
  /** The export task */
  task: Captur3dFloorplanGenerationTask;
};

/** @returns The card for the floor plan generation task */
export function FloorplanGenerationCard({
  task,
}: FloorplanGenerationCardProps): JSX.Element {
  const refElement = useAppSelector((state) =>
    selectAncestor(
      selectIElement(task.iElementId)(state),
      (e) => isIElementSectionDataSession(e) || isIElementGenericDataset(e),
    )(state),
  );

  const { projectApiClient } = useApiClientContext();

  const downloadActions = useAppSelector(
    selectDownloadActions(projectApiClient, task.iElementId),
  );

  let cardName: string | JSX.Element = "Export";
  if (refElement?.name) {
    cardName = <NoTranslate>{refElement.name}</NoTranslate>;
  }

  return (
    <GenericCardLayout
      name={cardName}
      subText="Floor plan generation"
      startTime={task.createdAt}
      menu={downloadActions}
      menuButtonLabel="Download"
    >
      {isBackgroundTaskActive(task.state) && (
        <CardProgress label="Exporting..." task={task} />
      )}
    </GenericCardLayout>
  );
}

/**
 * The file types supported for download.
 * The file types are ordered by importance for the customer.
 * Some formats are marked as "beta", as they do not meet our quality standards yet.
 */
const SUPPORTED_FLOOR_PLAN_FORMATS = [
  { fileSuffix: ".pdf", isBeta: false },
  { fileSuffix: ".dwg", isBeta: true },
  { fileSuffix: ".rvt", isBeta: true },
  { fileSuffix: ".png", isBeta: false },
  { fileSuffix: ".jpg", isBeta: false },
  { fileSuffix: ".svg", isBeta: false },
];

/**
 * @returns The actions for downloading the generated floor plan in different file formats
 * @param state Current app state
 * @param projectApi Project api instance to sign urls with
 * @param elementId The id of the floor plan generation layer containing all attachments
 */
const selectDownloadActions = curryAppSelector(
  createSelector(
    [
      (state: RootState) => state,
      (state: RootState, projectApi: ProjectApi) => projectApi,
      (state: RootState, projectApi: ProjectApi, elementId?: GUID | null) =>
        selectIElement(elementId)(state),
      (state: RootState) =>
        selectHasFeature(Features.FloorPlanBetaFormats)(state),
    ],
    (state, projectApi, floorPlanGenerationLayer, canDownloadBetaFormats) => {
      if (!floorPlanGenerationLayer) return [];

      return SUPPORTED_FLOOR_PLAN_FORMATS.filter(
        ({ isBeta }) => !isBeta || canDownloadBetaFormats,
      )
        .map(({ fileSuffix, isBeta }) =>
          selectDownloadActionForType(
            projectApi,
            floorPlanGenerationLayer,
            fileSuffix,
            isBeta,
          )(state),
        )
        .filter(isValid);
    },
  ),
);

/**
 * @returns The menu action to download a floor plan in a specific file type, or undefined if the file type is not available.
 * @param projectApi Project api instance to sign urls with
 * @param floorPlanGenerationLayer The iElement containing the generated floor plan attachments
 * @param fileSuffix The file type to download.
 * @param isBetaFormat Whether the file type is considered beta-quality
 */
function selectDownloadActionForType(
  projectApi: ProjectApi,
  floorPlanGenerationLayer: IElement,
  fileSuffix: string,
  isBetaFormat: boolean,
) {
  return (state: RootState): CardAction | undefined => {
    const attachment = selectChildDepthFirst(
      floorPlanGenerationLayer,
      (el): el is IElementAttachment =>
        isIElementAttachment(el) &&
        !!el.fileName?.toLowerCase().endsWith(fileSuffix),
    )(state);

    if (!attachment) return;

    return {
      name: (
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
          minWidth="80px"
        >
          <FaroText variant="bodyM" className={NO_TRANSLATE_CLASS}>
            {fileSuffix}
          </FaroText>
          {isBetaFormat && <BetaBadge />}
        </Stack>
      ),
      async callback() {
        const signedDownloadUrl = await createSignedUrlForIElementUri(
          projectApi,
          attachment,
        ).requestSignedUrl();

        downloadFile(signedDownloadUrl, undefined, true);
      },
    };
  };
}
