import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** This defines all the steps to align point cloud to sheet*/
export enum CloudToSheetAlignmentStep {
  setElevation = "setElevation",
  pairPoints = "pairPoints",
}

/** State managing data exchange during cloud-to-sheet alignment session */
type CloudToSheetAlignmentToolState = {
  /** aligned element ID used during current alignment session (cloud) */
  alignedElementId?: GUID;

  /** reference element ID used during current alignment session (sheet) */
  referenceElementId?: GUID;

  /** Alignment step */
  step: CloudToSheetAlignmentStep;

  /** elevation of aligned element relative to reference element in meters */
  elevation: number;

  /** if true clipping box in setElevation step is enabled */
  isClippingBoxEnabled: boolean;

  /** if true, extract cross section at elevation defined on first step for cloud tomographic view */
  isCrossSectionEnabled: boolean;

  /**
   * Incremental transform of the sheet to cloud. Translations are in meters.
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /** anchor positions defined in split screen alignment */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;

  /** alignment screen layout */
  alignmentLayout: AlignmentViewLayout;

  /** alignment screen split direction */
  splitDirection: SplitDirection;
};

const initialState: CloudToSheetAlignmentToolState = {
  step: CloudToSheetAlignmentStep.setElevation,
  alignmentAnchorPositions: {},
  isCrossSectionEnabled: true,
  isClippingBoxEnabled: false,
  alignmentLayout: AlignmentViewLayout.splitScreen,
  splitDirection: SplitDirection.horizontalSplit,
  elevation: 0,
};

const cloudToSheetAlignmentModeSlice = createSlice({
  initialState,
  name: "cloudToSheetAlignmentMode",
  reducers: {
    setStepForCloudToSheetAlignment(
      state,
      action: PayloadAction<CloudToSheetAlignmentStep>,
    ) {
      state.step = action.payload;
    },

    setCrossSectionEnabledForCloudToSheetAlignment(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isCrossSectionEnabled = action.payload;
    },

    setClippingBoxEnabledForCloudToSheetAlignment(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isClippingBoxEnabled = action.payload;
    },

    setAlignedElementIdForCloudToSheetAlignment(
      state,
      action: PayloadAction<GUID>,
    ) {
      state.alignedElementId = action.payload;
    },

    setReferenceElementIdForCloudToSheetAlignment(
      state,
      action: PayloadAction<GUID>,
    ) {
      state.referenceElementId = action.payload;
    },

    setElevationForCloudToSheetAlignment(state, action: PayloadAction<number>) {
      state.elevation = action.payload;
    },

    setIncrementalTransformForCloudToSheetAlignment(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setAlignedElementAnchor1ForCloudToSheetAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setAlignedElementAnchor2ForCloudToSheetAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },

    setReferenceElementAnchor1ForCloudToSheetAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setReferenceElementAnchor2ForCloudToSheetAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },

    resetCloudToSheetAlignment(state) {
      state.step = CloudToSheetAlignmentStep.setElevation;
      state.referenceElementId = undefined;
      state.alignedElementId = undefined;
      state.elevation = 0;
      state.isClippingBoxEnabled = false;
      state.incrementalTransform = undefined;
      state.alignmentAnchorPositions = {};
      state.alignmentLayout = AlignmentViewLayout.splitScreen;
      state.splitDirection = SplitDirection.horizontalSplit;
    },

    setLayoutForCloudToSheetAlignment(
      state,
      action: PayloadAction<AlignmentViewLayout>,
    ) {
      state.alignmentLayout = action.payload;
    },

    setSplitDirectionForCloudToSheetAlignment(
      state,
      action: PayloadAction<SplitDirection>,
    ) {
      state.splitDirection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  resetCloudToSheetAlignment,
  setStepForCloudToSheetAlignment,
  setAlignedElementIdForCloudToSheetAlignment,
  setReferenceElementIdForCloudToSheetAlignment,
  setIncrementalTransformForCloudToSheetAlignment,
  setElevationForCloudToSheetAlignment,
  setClippingBoxEnabledForCloudToSheetAlignment,
  setCrossSectionEnabledForCloudToSheetAlignment,
  setLayoutForCloudToSheetAlignment,
  setAlignedElementAnchor1ForCloudToSheetAlignment,
  setAlignedElementAnchor2ForCloudToSheetAlignment,
  setReferenceElementAnchor1ForCloudToSheetAlignment,
  setReferenceElementAnchor2ForCloudToSheetAlignment,
  setSplitDirectionForCloudToSheetAlignment,
} = cloudToSheetAlignmentModeSlice.actions;

export const cloudToSheetAlignmentModeReducer =
  cloudToSheetAlignmentModeSlice.reducer;
