import { BackgroundTask } from "@/utils/background-tasks";
import {
  CopyIcon,
  FaroIconButton,
  FaroText,
  neutral,
  NO_TRANSLATE_CLASS,
  useToast,
} from "@faro-lotv/flat-ui";
import {
  SceneWorkerErrorCode,
  validateSceneConversionResult,
} from "@faro-lotv/service-wires";
import { Tooltip } from "@mui/material";
import { Stack } from "@mui/system";

/**
 * @returns error details for a task, undefined if not available
 * @param task The task to get error details for
 */
export function taskErrorDetails(
  task: BackgroundTask,
): JSX.Element | undefined {
  if (
    task.errorCode === SceneWorkerErrorCode.partialScanProcessingFailure &&
    validateSceneConversionResult(task.metadata?.result) &&
    task.metadata.result.failedScans
  ) {
    return (
      <>
        <FaroText variant="labelS" sx={{ textTransform: "uppercase" }}>
          Failed scans
        </FaroText>
        <CopyableList
          items={task.metadata.result.failedScans.map((scan) => scan.scanName)}
        />
      </>
    );
  }
}

type CopyableListProps = {
  /** The items to show in the list */
  items: string[];
};

/** @returns a list of items with a button to copy them to the clipboard */
function CopyableList({ items }: CopyableListProps): JSX.Element {
  return (
    <Stack className={NO_TRANSLATE_CLASS}>
      {items.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{
            color: neutral[600],

            ".MuiIconButton-root": {
              opacity: 0,
              transition: "opacity 0.1s",
            },

            ":hover": {
              ".MuiIconButton-root": {
                opacity: 1,
              },
            },
          }}
        >
          <FaroText variant="bodyS" color="inherit">
            {item}
          </FaroText>
          <CopyButton text={item} />
        </Stack>
      ))}
    </Stack>
  );
}

type CopyButtonProps = {
  /** The text to copy with the button */
  text: string;
};

/** @returns an icon button to copy text with feedback for the user */
function CopyButton({ text }: CopyButtonProps): JSX.Element {
  const { openToast } = useToast();

  return (
    <Tooltip title="Copy to clipboard">
      <FaroIconButton
        size="s"
        margin={0.5}
        onClick={() => {
          navigator.clipboard.writeText(text);
          openToast({ title: "Copied to clipboard" });
        }}
      >
        <CopyIcon color="inherit" />
      </FaroIconButton>
    </Tooltip>
  );
}
