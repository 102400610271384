import {
  RenderDispatch,
  flagSubtree,
} from "@/modes/overview-mode/render-dispatch";
import { isSheetObject } from "@/object-cache-type-guard";
import {
  ClearPass,
  CopyToScreenPass,
  DesaturatePass,
  DesaturatePassProps,
  EffectPipeline,
  FilteredRenderPass,
} from "@faro-lotv/app-component-toolbox";
import { walkWithQueue } from "@faro-lotv/foundation";
import { useFrame } from "@react-three/fiber";
import { Object3D } from "three";

export type DesaturationSheetsPipelineProps = DesaturatePassProps & {
  /** Make the background of the framebuffer completely transparent */
  transparentBackground?: boolean;
};

/**
 * @returns a pipeline to apply desaturation to all sheets in the scene
 *
 * The enabled flag from the DesaturatePass should only be used when the R3f canvas or in a View
 * using DesaturationPipeline is sure to have only one EffectPipeline
 *
 * Having this EffectPipeline along with another one in a R3f canvas or in a View using DesaturationPipeline would conflict with each other
 */
export function DesaturationSheetsPipeline({
  transparentBackground = false,
  ...rest
}: DesaturationSheetsPipelineProps): JSX.Element {
  useFrame(({ scene }) => {
    const queue: Object3D[] = [scene];
    walkWithQueue(queue, (o, append) => {
      if (isSheetObject(o)) {
        flagSubtree(o, RenderDispatch.Floorplan);
        return;
      }
      append(...o.children);
    });
  }, 0);

  return (
    <EffectPipeline>
      {transparentBackground && (
        // A clear pass is required before a transparent background pass, otherwise the later transparent objects
        // such as trajectories won't be composed correctly.
        <ClearPass />
      )}
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type === RenderDispatch.Floorplan
        }
        transparentBackground={transparentBackground}
      />
      <DesaturatePass {...rest} />
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type !== RenderDispatch.Floorplan
        }
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipeline>
  );
}
